@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

/* Landscape phones and down */
@media (max-width: 480px) {
  .App {
    display: flex;
    flex-direction: column;
    color: #392467;
    font-family: "Open Sans";
    margin: 0;
    text-align: center;
  }
  
  .bio-div {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 30px 0 30px 50px;
  }
  
  .bio-pic {
    max-width: 80%;
    height: auto;
  }
  
  .bio-text {
    line-height: 1.7rem;
    margin: 30px 0 30px 0;
    max-width: 90%;
    text-align: left;
  }
  
  .btn-classic {
    padding: 8px;
    border: 2px solid white;
    border-radius: 35%;
    font-weight: bold;
  }
  
  .btn-classic:hover {
    cursor: pointer;
    background: rgb(183,132,183);
    background: linear-gradient(133deg, rgba(183,132,183,1) 1%, rgba(85,43,210,1) 50%);
  }
  
  .content-container {
    align-items: left;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .cover {
    height: auto;
    margin: 10px 0 20px 0;
    width: 35vh;
  }

  .custom-iframe {
    width: 280px;
    height: 150px;
  }
  
  .footer {
    align-items: center;
    background-color: #27005D;
    color: white;
    display: flex;
    flex-direction: column;
    font-weight: 100;
    font-size: 12px;
    height: 12vh;
    justify-content: center;
    width: 100%;
    z-index: 100;
  }
  
  .header {
    align-items: center;
    align-self: end;
    background-color: #27005D;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    color: white;
    display: inline-flex;
    font-weight: 100;
    font-size: 16px;
    height: 12vh;
    justify-content: space-between;
    padding: 0 2%;
    position: fixed;
    width: 96%;
    z-index: 100;
  }

  .heading {
    color: #392467;
    font-weight: bold;  
    opacity: 0.7;
  }

  .hyperlink {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-decoration: none;
  }
  
  .hyperlink:hover {
    cursor: pointer;
  }
  
  .item {
    align-items: left;
    display: flex;
    flex-direction: column;
    margin: 20px 30px 50px 50px;
    text-align: left;
  }
  
  .main-content {
    margin-left: 10px;
    min-height: 100vh;
  }
  
  .paragraph {
    margin-top: 20px;
  }
  
  .review {
    font-style: italic;
  }
  
  .review-row {
    margin-bottom: 20px;
  }
  
  .review-section {
    margin: 20px 0 0 20px;
  }
  
  .title {
    font-size: 22px;
    font-style: italic;
    color: #B784B7;
  }
  
  .title-main {
    background: rgb(183,132,183);
    background: linear-gradient(133deg, rgba(183,132,183,1) 1%, rgba(85,43,210,1) 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 22px;
  }

  h1 {
    font-size: 24px;
    margin-left: 20px;
  }
  
  h3 {
    margin: 5px 0;
  }
  
  h4 {
    margin: 10px 0;
  }
  
  li {
    list-style-type: none;
  }
  
  p {
    padding: 0;
    margin: 5px 0;
  }
 }

 @media (min-width: 481px) and (max-width: 600px) {
  .App {
    display: flex;
    flex-direction: column;
    color: #392467;
    font-family: "Open Sans";
    margin: 0;
    text-align: center;
  }
  
  .bio-div {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 30px 0 30px 50px;
  }
  
  .bio-pic {
    max-width: 80%;
    height: auto;
  }
  
  .bio-text {
    line-height: 1.7rem;
    margin: 30px 0 30px 0;
    max-width: 90%;
    text-align: justify;
  }
  
  .btn-classic {
    padding: 8px;
    border: 2px solid white;
    border-radius: 35%;
    font-weight: bold;
    margin-right: 20px;
  }
  
  .btn-classic:hover {
    cursor: pointer;
    background: rgb(183,132,183);
    background: linear-gradient(133deg, rgba(183,132,183,1) 1%, rgba(85,43,210,1) 50%);
  }
  
  .content-container {
    align-items: left;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .cover {
    height: auto;
    margin: 10px 0 20px 0;
    width: 35vh;
  }

  .custom-iframe {
    width: 280px;
    height: 150px;
  }
  
  .footer {
    align-items: center;
    background-color: #27005D;
    color: white;
    display: flex;
    flex-direction: column;
    font-weight: 100;
    font-size: 12px;
    height: 12vh;
    justify-content: center;
    width: 100%;
    z-index: 100;
  }
  
  .header {
    align-items: center;
    align-self: end;
    background-color: #27005D;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    color: white;
    display: inline-flex;
    font-weight: 100;
    font-size: 16px;
    height: 12vh;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 100;
  }

  .heading {
    color: #392467;
    font-weight: bold;  
    margin-left: 20px; 
    opacity: 0.7;
  }
  
  .hyperlink {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-decoration: none;
  }
  
  .hyperlink:hover {
    cursor: pointer;
  }
  
  .item {
    align-items: left;
    display: flex;
    flex-direction: column;
    margin: 20px 30px 50px 50px;
    text-align: left;
  }
  
  .main-content {
    margin-left: 10px;
    min-height: 100vh;
  }
  
  .paragraph {
    margin-top: 20px;
  }
  
  .review {
    font-style: italic;
  }
  
  .review-row {
    margin-bottom: 20px;
  }
  
  .review-section {
    margin: 20px 0 0 50px;
  }
  
  .title {
    font-size: 22px;
    font-style: italic;
    color: #B784B7;
  }
  
  .title-main {
    background: rgb(183,132,183);
    background: linear-gradient(133deg, rgba(183,132,183,1) 1%, rgba(85,43,210,1) 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 22px;
  }

  h1 {
    font-size: 24px;
    margin-left: 20px;
  }
  
  h3 {
    margin: 5px 0;
  }
  
  h4 {
    margin: 10px 0;
  }
  
  li {
    list-style-type: none;
  }
  
  p {
    padding: 0;
    margin: 5px 0;
  }
 }

 @media (min-width: 600px) and (max-width: 767px) {
  .App {
    display: flex;
    flex-direction: column;
    color: #392467;
    font-family: "Open Sans";
    text-align: center;
  }
  
  .bio-div {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 30px 0 30px 50px;
  }
  
  .bio-pic {
    max-width: 80%;
    height: auto;
  }
  
  .bio-text {
    line-height: 1.7rem;
    margin: 30px 0 30px 0;
    max-width: 90%;
    text-align: justify;
  }
  
  .btn-classic {
    padding: 8px;
    border: 2px solid white;
    border-radius: 35%;
    font-weight: bold;
  }
  
  .btn-classic:hover {
    cursor: pointer;
    background: rgb(183,132,183);
    background: linear-gradient(133deg, rgba(183,132,183,1) 1%, rgba(85,43,210,1) 50%);
  }
  
  .content-container {
    align-items: left;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .cover {
    height: auto;
    margin: 10px 0 20px 0;
    width: 35vh;
  }

  .custom-iframe {
    width: 280px;
    height: 150px;
  }
  
  .footer {
    align-items: center;
    background-color: #27005D;
    color: white;
    display: flex;
    flex-direction: column;
    font-weight: 100;
    font-size: 12px;
    height: 12vh;
    justify-content: center;
    margin-left: 200px;
    width: calc(100%-200px);
    z-index: 100;
  }
  
  .header {
    align-items: center;
    align-self: end;
    background-color: #27005D;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    color: white;
    display: inline-flex;
    font-weight: 100;
    font-size: 16px;
    height: 12vh;
    justify-content: space-between;
    padding: 0 30px;
    position: fixed;
    width: 50%;
    z-index: 100;
  }

  .heading {
    color: #392467;
    font-weight: bold;  
    margin-left: 20px; 
    opacity: 0.7;
  }
  
  .hyperlink {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-decoration: none;
  }
  
  .hyperlink:hover {
    cursor: pointer;
  }
  
  .item {
    align-items: left;
    display: flex;
    flex-direction: column;
    margin: 20px 30px 50px 50px;
    text-align: left;
  }
  
  .main-content {
    margin-left: 200px;
    min-height: 100vh;
  }
  
  .paragraph {
    margin-top: 20px;
  }
  
  .review {
    font-style: italic;
  }
  
  .review-row {
    margin-bottom: 20px;
  }
  
  .review-section {
    margin: 20px 0 0 50px;
  }
  
  .title {
    font-size: 22px;
    font-style: italic;
    color: #B784B7;
  }
  
  .title-main {
    background: rgb(183,132,183);
    background: linear-gradient(133deg, rgba(183,132,183,1) 1%, rgba(85,43,210,1) 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 22px;
  }

  h1 {
    font-size: 24px;
    margin-left: 20px;
  }
  
  h3 {
    margin: 5px 0;
  }
  
  h4 {
    margin: 10px 0;
  }
  
  li {
    list-style-type: none;
  }
  
  p {
    padding: 0;
    margin: 5px 0;
  }
 }

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) { 
  .App {
    display: flex;
    flex-direction: column;
    color: #392467;
    font-family: "Open Sans";
    text-align: center;
  }
  
  .bio-div {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 30px 0 30px 50px;
  }
  
  .bio-pic {
    max-width: 40%;
    height: auto;
  }
  
  .bio-text {
    line-height: 2rem;
    margin: 30px 0 30px 0;
    max-width: 85%;
    text-align: justify;
  }
  
  .btn-classic {
    padding: 8px;
    border: 2px solid white;
    border-radius: 35%;
    font-weight: bold;
  }
  
  .btn-classic:hover {
    cursor: pointer;
    background: rgb(183,132,183);
    background: linear-gradient(133deg, rgba(183,132,183,1) 1%, rgba(85,43,210,1) 50%);
  }
  
  .content-container {
    align-items: left;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .cover {
    height: auto;
    margin: 10px 0 20px 0;
    width: 50vh;
  }

  .custom-iframe {
    width: 450px;
    height: 270px;
  }
  
  .footer {
    align-items: center;
    background-color: #27005D;
    color: white;
    display: flex;
    flex-direction: row;
    font-weight: 100;
    font-size: 12px;
    height: 12vh;
    justify-content: space-between;
    margin-left: 200px;
    padding: 0 30px;
    width: calc(100%-200px);
    z-index: 100;
  }
  
  .header {
    align-items: center;
    align-self: end;
    background-color: #27005D;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    color: white;
    display: inline-flex;
    font-weight: 100;
    font-size: 16px;
    height: 12vh;
    justify-content: space-between;
    margin-left: auto;
    padding: 0 40px;
    position: fixed;
    width: 51%;
    z-index: 100;
  }

  .heading {
    color: #392467;
    font-weight: bold;  
    margin-left: 20px; 
    opacity: 0.7;
  }
  
  .hyperlink {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-decoration: none;
  }
  
  .hyperlink:hover {
    cursor: pointer;
  }
  
  .item {
    align-items: left;
    display: flex;
    flex-direction: column;
    margin: 20px 30px 50px 50px;
    text-align: left;
  }
  
  .main-content {
    margin-left: 220px;
    min-height: 100vh;
  }
  
  .paragraph {
    margin-top: 20px;
  }
  
  .review {
    font-style: italic;
  }
  
  .review-row {
    margin-bottom: 20px;
  }
  
  .review-section {
    margin: 20px 0 0 50px;
  }
  
  .title {
    font-size: 22px;
    font-style: italic;
    color: #B784B7;
  }
  
  .title-main {
    background: rgb(183,132,183);
    background: linear-gradient(133deg, rgba(183,132,183,1) 1%, rgba(85,43,210,1) 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 22px;
  }
  
  h3 {
    margin: 5px 0;
  }
  
  h4 {
    margin: 10px 0;
  }
  
  li {
    list-style-type: none;
  }
  
  p {
    padding: 0;
    margin: 5px 0;
  }
}

/* Desktop */
@media (min-width: 979px) {
  .App {
    display: flex;
    flex-direction: column;
    color: #392467;
    font-family: "Open Sans";
    text-align: center;
  }
  
  .bio-div {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-left: 50px;
  }
  
  .bio-pic {
    margin-right: 20px;
    max-width: 35%;
    height: auto;
  }
  
  .bio-text {
    line-height: 2rem;
    margin-left: 45px;
    max-width: 45%;
    text-align: justify;
  }
  
  .btn-classic {
    padding: 8px;
    border: 2px solid white;
    border-radius: 35%;
    font-weight: bold;
  }
  
  .btn-classic:hover {
    cursor: pointer;
    background: rgb(183,132,183);
    background: linear-gradient(133deg, rgba(183,132,183,1) 1%, rgba(85,43,210,1) 50%);
  }
  
  .content-container {
    align-items: left;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .cover {
    height: auto;
    margin: 10px 0 20px 0;
    width: 50vh;
  }
  
  .custom-iframe {
    width: 560px;
    height: 315px;
  }

  .footer {
    align-items: center;
    background-color: #27005D;
    color: white;
    display: flex;
    flex-direction: row;
    font-weight: 100;
    font-size: 12px;
    height: 12vh;
    justify-content: space-between;
    margin-left: 200px;
    padding: 0 30px;
    width: calc(100%-200px);
    z-index: 100;
  }
  
  .header {
    align-items: center;
    align-self: end;
    background-color: #27005D;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    color: white;
    display: inline-flex;
    font-weight: 100;
    font-size: 18px;
    height: 12vh;
    justify-content: space-between;
    margin-left: auto;
    padding: 0 60px;
    position: fixed;
    width: 50%;
    z-index: 100;
  }
  
  .heading {
    color: #392467;
    font-weight: bold;  
    margin-left: 20px; 
    opacity: 0.7;
  }

  .hyperlink {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-decoration: none;
  }
  
  .hyperlink:hover {
    cursor: pointer;
  }
  
  .item {
    align-items: left;
    display: flex;
    flex-direction: column;
    margin: 20px 30px 50px 50px;
    text-align: left;
  }
  
  .main-content {
    margin-left: 220px;
    min-height: 100vh;
  }
  
  .paragraph {
    margin-top: 20px;
  }
  
  .review {
    font-style: italic;
  }
  
  .review-row {
    margin-bottom: 20px;
  }
  
  .review-section {
    margin: 20px 0 0 50px;
  }
  
  .title {
    font-size: 22px;
    font-style: italic;
    color: #B784B7;
  }
  
  .title-main {
    background: rgb(183,132,183);
    background: linear-gradient(133deg, rgba(183,132,183,1) 1%, rgba(85,43,210,1) 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 22px;
  }
  
  h3 {
    margin: 5px 0;
  }
  
  h4 {
    margin: 10px 0;
  }
  
  li {
    list-style-type: none;
  }
  
  p {
    padding: 0;
    margin: 5px 0;
  }

 }